import { template as template_262d9546609a48a6b0c5daf12bab190b } from "@ember/template-compiler";
import PixBannerAlert from '@1024pix/pix-ui/components/pix-banner-alert';
import { t } from 'ember-intl';
export default template_262d9546609a48a6b0c5daf12bab190b(`
  <PixBannerAlert @type="info">
    {{t "pages.modulix.beta-banner"}}
  </PixBannerAlert>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
