import { template as template_3b947e540ef744c1a2262a0054f5dbc4 } from "@ember/template-compiler";
export default template_3b947e540ef744c1a2262a0054f5dbc4(`
  <h1>
    {{! template-lint-disable "no-bare-strings" }}
    Page Certificat V3
  </h1>
  <p>{{@certification.fullName}} {{@certification.birthdate}}</p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
