import { template as template_16d94147e0324b6689e9760141af448b } from "@ember/template-compiler";
export default template_16d94147e0324b6689e9760141af448b(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
