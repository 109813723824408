import { template as template_7c1fb0409e7c4ad39e07223332d73d20 } from "@ember/template-compiler";
import PixIcon from '@1024pix/pix-ui/components/pix-icon';
import { service } from '@ember/service';
import Component from '@glimmer/component';
export default class ModulixGrainTag extends Component {
    @service
    intl;
    get typeText() {
        return this.intl.t(`pages.modulix.grain.tag.${this.args.type}`);
    }
    get iconName() {
        switch(this.args.type){
            case 'lesson':
                return 'book';
            case 'activity':
                return 'cogsMagic';
            case 'discovery':
                return 'doorOpen';
            case 'challenge':
                return 'think';
            case 'summary':
                return 'mountain';
            default:
                return 'book';
        }
    }
    static{
        template_7c1fb0409e7c4ad39e07223332d73d20(`
    <div class="tag tag--{{@type}}">
      <PixIcon @name={{this.iconName}} @ariaHidden={{true}} />
      <span>{{this.typeText}}</span>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
