import { template as template_15ec3f6c811a44a492bd31cd6acf93e1 } from "@ember/template-compiler";
import htmlUnsafe from 'mon-pix/helpers/html-unsafe';
export default template_15ec3f6c811a44a492bd31cd6acf93e1(`
  <div class="element-text">
    {{htmlUnsafe @text.content}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
